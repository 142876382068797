import Vue from "vue";
import uaaAxios from "@/utils/authAxios";

const TBK_WEBPAYPLUS_API = `${process.env.VUE_APP_API_URL_BASE}${process.env.VUE_APP_TBK_API}/webpayplus`;

export default {
  confirmTransaction: async (body) => {
    try {
      const response = await uaaAxios.post(`${TBK_WEBPAYPLUS_API}/result?token_ws=${body.token_ws}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  // findById
  getTransaction: function (transactionId) {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${TBK_WEBPAYPLUS_API}/users/${userId}/transactions/${transactionId}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  // findAll
  getTransactions: function () {
    let userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`${TBK_WEBPAYPLUS_API}/users/${userId}/transactions`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },
};
