<template>
  <v-card class="pa-3 px-5" width="400">
    <v-row justify="start">
      <div class="text-h6 mt-2 mx-2">Detalles del pago:</div>
    </v-row>
    <v-row justify="start">
      <v-col>
        <div class="roboto text-subtitle-1">Código de la transacción:</div>
        <div class="text-profile roboto">
          {{ voucher.buyOrder }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Monto pagado:</div>
        <div class="text-profile roboto">$ {{ voucher.amount | numeral("0,0") }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Fecha de la transacción:</div>
        <div class="text-profile roboto">
          {{ voucher.createdAt | formatDate }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Hora de la transacción:</div>
        <div class="text-profile roboto">
          {{ voucher.createdAt | formatDateHour }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Tarjeta:</div>
        <div class="text-profile roboto">*************{{ voucher.last4CardDigits }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Código de autorización:</div>
        <div class="text-profile roboto">*************{{ voucher.authCode }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Tipo de pago:</div>
        <div class="text-profile roboto">
          {{ planConstants.CardType2[voucher.cardType] }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="voucher.sharesNumber">
      <v-col>
        <div class="roboto text-subtitle-1">Número de cuotas:</div>
        <div class="text-profile roboto">
          {{ voucher.sharesNumber }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import webpayplusService from "@/services/billing/webpayplusService";
import subscriptionService from "@/services/subscriptionService";
import planConstants from "@/utils/planConstants.json";

export default {
  name: "WebpayPlusResult",

  components: {},
  props: {
    transactionId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    planConstants: planConstants,

    voucher: {
      id: "",
      userId: "",
      buyOrder: null,
      amount: null,
      createdAt: null,
      paymentMethod: null,
      authCode: null,
      cardType: null,
      last4CardDigits: null,
      tbkTransactionId: null,
      inscription: null,
      sharesAmount: null,
      sharesNumber: null,
    },
  }),

  mounted() {
    this.getVoucher();
  },
  created() {},

  methods: {
    getVoucher() {
      webpayplusService
        .getTransaction(this.transactionId)
        .then((transaction) => {
          this.voucher = transaction;
          this.voucher.paymentMethod = "WEBPAY_PLUS_NORMAL";
          this.addPayment();
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },

    addPayment() {
      subscriptionService
        .getCurrentUserSubscription()
        .then((subscription) => {
          this.subscription = subscription;

          let paymentDTO = {
            paymentDate: this.voucher.createdAt,
            paymentMethod: this.voucher.paymentMethod,
            planId: this.subscription.plan.id,
            buyOrder: this.voucher.buyOrder,
            amount: this.voucher.amount,
            paymentMethodData: JSON.stringify({
              authCode: this.voucher.authCode,
              cardType: this.voucher.cardType,
              last4CardDigits: this.voucher.last4CardDigits,
              tbkTransactionId: this.voucher.tbkTransactionId,
              sharesAmount: this.voucher.sharesAmount,
              sharesNumber: this.voucher.sharesNumber,
            }),
          };

          subscriptionService
            .addPayment(this.subscription.id, paymentDTO)
            .then((subscription) => (this.subscription = subscription))
            .catch((err) => this.$log.error("Error trying to add a payment to subscription - ", err))
            .then(() => this.$emit("loading", false));
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
};
</script>
