<template>
  <v-card class="pa-3 px-5" width="400" v-if="!isLoading">
    <v-row>
      <div class="text-h6 mt-2 mx-2">Detalles del pago:</div>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Identificador de la suscripci&oacute;n:</div>
        <div class="text-profile roboto">
          {{ paypalSubscriptionDetails.id }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Monto pagado:</div>
        <div class="text-profile roboto">{{ formattedAmount | currency }} USD</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Fecha de la transacción:</div>
        <div class="text-profile roboto">
          {{ paypalSubscriptionDetails.billing_info.last_payment.time | formatDate }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="roboto text-subtitle-1">Hora de la transacción:</div>
        <div class="text-profile roboto">
          {{ paypalSubscriptionDetails.billing_info.last_payment.time | formatDateHour }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import paypalSubscriptionsService from "@/services/billing/paypalSubscriptionsService";
import subscriptionService from "@/services/subscriptionService";
import planConstants from "@/utils/planConstants.json";
import moment from "moment";

export default {
  name: "PaypalSubscriptionResult",

  components: {},

  data: () => ({
    planConstants: planConstants,
    paypalSubscriptionData: {},
    paypalSubscriptionDetails: {},
    subscription: {},
    clientInfo: {},
    isLoading: true,
  }),
  computed: {
    formattedAmount: function () {
      if (this.paypalSubscriptionDetails.billing_info) {
        var amount = this.paypalSubscriptionDetails.billing_info.last_payment.amount.value.replace(".", ",");
      }
      return amount;
    },
  },
  created() {
    this.paypalSubscriptionData = JSON.parse(localStorage.getItem("PaypalSubscriptionData"));
    this.clientInfo = JSON.parse(localStorage.getItem("clientInfo"));
    this.addPayment();
  },

  methods: {
    addPayment() {
      paypalSubscriptionsService
        .getPaypalSubscriptionDetails(this.paypalSubscriptionData.subscriptionID, this.clientInfo.countryCode)
        .then((subscriptionDetails) => {
          this.paypalSubscriptionDetails = subscriptionDetails;
          subscriptionService
            .getCurrentUserSubscription()
            .then((subscription) => {
              this.subscription = subscription;

              let paymentDTO = {
                paymentDate: moment().toISOString(this.paypalSubscriptionDetails.create_time),
                paymentMethod: "PAYPAL_SUBSCRIPTION",
                planId: this.subscription.plan.id,
                buyOrder: this.paypalSubscriptionData.orderID,
                amount: this.paypalSubscriptionDetails.billing_info.last_payment.amount.value,
                paymentMethodData: JSON.stringify({
                  paypalSubscriptionId: this.paypalSubscriptionDetails.id,
                  paypalPlanId: this.paypalSubscriptionDetails.plan_id,
                  paymentDate: moment().toISOString(this.paypalSubscriptionDetails.billing_info.last_payment.time),
                  paymentAmount: this.paypalSubscriptionDetails.billing_info.last_payment.amount.value,
                  paypalPayer: {
                    id: this.paypalSubscriptionDetails.subscriber.payer_id,
                    email: this.paypalSubscriptionDetails.subscriber.email_address,
                    name: {
                      givenName: this.paypalSubscriptionDetails.subscriber.name.given_name,
                      surname: this.paypalSubscriptionDetails.subscriber.name.surname,
                    },
                  },
                }),
              };

              subscriptionService
                .addPayment(this.subscription.id, paymentDTO)
                .then((subscription) => (this.subscription = subscription))
                .catch((err) => this.$log.error("Error trying to add a payment to subscription - ", err))
                .then(() => {
                  this.isLoading = false;
                  this.$emit("loading", false);
                });
            })
            .catch((err) => this.$log.error("Unexpected error: ", err));
        })
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => {
          localStorage.removeItem("PaypalSubscriptionData");
        });
    },
  },
};
</script>
