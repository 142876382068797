<template>
  <div>
    <Loader :visible="isLoading" />
    <v-container>
      <div v-if="!isLoading">
        <div class="text-center mb-6">¡Tu pago se ha realizado con éxito!</div>
        <v-row justify="center">
          <v-img :src="require(`@/assets/img/Success-payment.svg`)" contain max-height="100" max-width="150" />
        </v-row>
        <div class="text-center roboto text-subtitle-1 mt-6">
          Hemos enviado un comprobante al correo <strong> {{ email }} </strong><br />
          También puedes descargar tu comprobante de pago o verlos en la sección
          <strong> Mis Pagos.</strong>
        </div>
      </div>
      <v-row justify="center" class="mt-6">
        <div v-if="paymentMethod === 'WEBPAY_PLUS_NORMAL'">
          <webpayPlusResult :transaction-id="transactionId" @loading="loading" />
        </div>
        <div v-else-if="paymentMethod === 'PAYPAL_SUBSCRIPTION'">
          <paypalSubscriptionResult @loading="loading" />
        </div>
        <div v-else-if="paymentMethod === 'PAYPAL_CHECKOUT'">
          <paypalCheckoutResult @loading="loading" />
        </div>
      </v-row>
      <v-row justify="center" class="mt-5">
        <!--         <v-btn
          class="tway-violet--text mr-4 roboto"
          large
          color="white"
          text
          :to="{ name: 'home' }"
        >
          VOLVER AL INICIO
        </v-btn>
        <v-btn
          large
          rounded
          color="tway-apple-green"
          class="white--text roboto"
          :to="{ name: 'my-payment' }"
        >
          VER MIS PAGOS
        </v-btn> -->
        <v-btn
          v-if="!isLoading"
          large
          rounded
          color="tway-apple-green"
          class="white--text roboto"
          @click="
            () => {
              showModal = true;
            }
          "
        >
          Finalizar
        </v-btn>
      </v-row>
      <v-dialog v-model="showModal" max-width="30em" persistent>
        <v-card class="pt-8 pb-8">
          <v-card-title class="d-block">
            <h1 class="text-h5 tway-header-1 text-center px-15" style="word-break: break-word">Importante!</h1>
          </v-card-title>
          <v-card-text class="text-center mt-7">
            <div class="px-md-10 px-0">
              <v-img :src="require(`@/assets/img/coming-soon-imo.svg`)" contain :max-height="130" />
              <div class="mt-5 text-center text-oboarding">
                Para que puedas disfrutar de las caracteristicas contratadas para la plataforma tway, es necesario que
                reinicies tu sesion!
              </div>
              <div class="mt-5 text-center text-oboarding">
                Al dar click en el boton aceptar, seras redirigido a la vista de inicio de sesion donde ingresaras tus
                credenciales y podras comenzar a aprovechar las ventajas de la Transformación Digital.
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              large
              rounded
              color="tway-violet"
              class="white--text roboto font-weight-bold"
              min-width="120"
              @click="
                () => {
                  logout();
                }
              "
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.title-profile {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}
.text-profile {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #757575;
}
</style>
<script>
import Loader from "@/components/Loader";
import webpayPlusResult from "@/views/billing/WebpayPlusResult";
import paypalSubscriptionResult from "@/views/billing/PaypalSubscriptionResult";
import paypalCheckoutResult from "@/views/billing/PaypalCheckoutResult";
import planConstants from "@/utils/planConstants.json";
import subscriptionService from "@/services/subscriptionService";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SuccessfulPayment",

  components: {
    webpayPlusResult,
    paypalSubscriptionResult,
    paypalCheckoutResult,
    Loader,
  },

  data: () => ({
    isLoading: true,
    email: "",
    firstName: "",
    planConstants: planConstants,
    user: {
      firstName: "",
    },
    subscription: {
      paymentMethod: "",
      payments: [],
      plan: {
        name: "",
      },
    },
    transactionId: "",
    showModal: false,
  }),

  async created() {
    this.paymentMethod = this.$route.query.paymentMethod;
    this.email = localStorage.getItem("userName");
    if (this.$route.query.paymentMethod == "WEBPAY_PLUS_NORMAL") {
      this.token = this.$route.query.token;
      this.action = this.$route.query.action;
      this.transactionId = this.$route.query.transactionId;
    }
  },
  computed: {
    ...mapGetters("subscription", ["getPaypalCheckoutData"]),
  },
  methods: {
    ...mapActions("authentication", ["doLogout"]),

    getPdf: function (pdf) {
      subscriptionService
        .getPdf("Comprobante_" + pdf)
        .then(function (response) {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Comprobante " + pdf + ".pdf";
          link.click();
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    logout() {
      this.doLogout()
        .then(() => this.$router.push({ name: "login" }))
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
    loading(flag) {
      this.isLoading = flag;
    },
  },
};
</script>
