import Vue from "vue";
import uaaAxios from "@/utils/authAxios";

export default {
  getPaypalSubscriptionDetails: function (paypalSubscriptionId, countryCode) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`/paypal-subscriptions/subscriptions/${paypalSubscriptionId}?countryCode=${countryCode}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },

  getPaypalOrderDetails: function (orderId, countryCode) {
    return new Promise((resolve, reject) => {
      uaaAxios
        .get(`/paypal-subscriptions/orders/${orderId}?countryCode=${countryCode}`)
        .then((response) => resolve(Vue._.get(response, "data")))
        .catch((err) => reject(err));
    });
  },
};
