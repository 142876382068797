<template>
  <v-container>
    <v-card class="pa-3 px-5" width="400" v-if="!isLoading">
      <v-row justify="start">
        <div class="text-h6 mt-2 mx-2">Detalles del pago:</div>
      </v-row>
      <v-row justify="start">
        <v-col>
          <div class="roboto text-subtitle-1">Identificador del pago:</div>
          <div class="text-profile roboto">
            {{ paypalOrderDetails.id }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="roboto text-subtitle-1">Monto pagado:</div>
          <div class="text-profile roboto">{{ formattedAmount | currency }} USD</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="roboto text-subtitle-1">Fecha de la transacción:</div>
          <div class="text-profile roboto">
            {{ paypalOrderDetails.create_time | formatDate }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="roboto text-subtitle-1">Hora de la transacción:</div>
          <div class="text-profile roboto">
            {{ paypalOrderDetails.create_time | formatDateHour }}
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import paypalSubscriptionsService from "@/services/billing/paypalSubscriptionsService";
import subscriptionService from "@/services/subscriptionService";
import planConstants from "@/utils/planConstants.json";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaypalCheckoutResult",

  components: {},

  data() {
    return {
      isLoading: true,
      planConstants: planConstants,
      paypalOrderDetails: {},
      subscription: {},
      clientInfo: {},
    };
  },

  computed: {
    ...mapGetters("subscription", ["getPaypalCheckoutData"]),
    ...mapGetters("CompanyStore", ["getCompany"]),
    formattedAmount: function () {
      if (this.paypalOrderDetails.purchase_units) {
        var amount = this.paypalOrderDetails.purchase_units[0].amount.value.replace(".", ",");
      }
      return amount;
    },
  },

  async created() {
    await this.setCompanyProfile();
    this.addPayment();
  },
  methods: {
    ...mapActions("authentication", ["setSidebarContent"]),
    ...mapActions("CompanyStore", ["setCompanyProfile"]),
    addPayment() {
      if (this.getPaypalCheckoutData.orderID == undefined) {
        this.$router.replace({ name: "my-payment" });
      }
      paypalSubscriptionsService
        .getPaypalOrderDetails(this.getPaypalCheckoutData.orderID, this.getCompany.countryCode)
        .then((orderDetails) => {
          this.paypalOrderDetails = orderDetails;
          subscriptionService
            .getCurrentUserSubscription()
            .then((subscription) => {
              this.subscription = subscription;

              let paymentDTO = {
                paymentDate: moment().toISOString(this.paypalOrderDetails.create_time),
                paymentMethod: "PAYPAL_CHECKOUT",
                planId: this.subscription.plan.id,
                buyOrder: this.getPaypalCheckoutData.orderID,
                amount: this.paypalOrderDetails.purchase_units[0].amount.value,
                paymentMethodData: JSON.stringify(this.paypalOrderDetails),
              };

              subscriptionService
                .addPayment(this.subscription.id, paymentDTO)
                .then((subscription) => {
                  this.subscription = subscription;
                  this.isLoading = false;
                  this.$emit("loading", false);
                })
                .catch((err) => this.$log.error("Error trying to add a payment to subscription - ", err));
            })
            .catch((err) => this.$log.error("Unexpected error: ", err));
        })
        .catch((err) => this.$log.error("Unexpected error: ", err));
    },
  },
};
</script>
